<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-sm-12">
                <Section>
                    <h1>Addressing the Challenges and Complexities of My Name</h1>
                    <i>Detailing the correct and incorrect ways to spell and pronounce my name</i>
                    <h2>Abstract</h2>
                    <p>In the course of human history, it is often necessary to make minor corrections in hopes of achieving a higher purpose, if not motivated by good will or strength of virtue, then by the danger of future misfortune. Thus, such small accumulated grievances must be corrected, or the results will no doubt be anything less than dire. In accordance with this dutiful deed, the purpose of this paper is to address the spelling, pronunciation, and recollection of my name. The examples, datagrams, figures, and textual content of the document contained herein shall summarize the motivation, common pitfalls, and possible resolution strategies related to my name.</p>
                    <h2>Literature Review</h2>
                    <p>Allow us to examine history in hopes of finding the motivations behind this name. Firstly, examine the historical figure <strong>Alexander the Great</strong>. At one point, ruler of Macedonia, an empire beyond grand for its time. Now examine the aforementioned text. Do you notice the phrase &ldquo;<strong>the Great</strong>&rdquo; positioned after the word Alexander? It can thus be reasonably inferred that the word Alexander has a positive connotation in this scenario, in accordance with the historical record. Has anyone ever mentioned &ldquo;Alex the Great&rdquo; to refer to &ldquo;Alexander the Great&rdquo;? No, of course not.</p>
                    <p>Now, let us examine another notable example, one from popular culture. It has come to my attention that there is a musical entitled <em>Hamilton</em>. An interesting proposition indeed, but what is more interesting is that this actually refers to the man, the myth, the legend, <strong>Alexander Hamilton</strong>, one of the founding fathers of the United States (in addition to a slew of various other accolades). A whole musical, dedicated to this phenomenal figure. In fact, he has his own song, entitled, (shockingly) &ldquo;Alexander Hamilton&rdquo;.&nbsp;</p>
                    <p>And I quote, some lyrics of the song:</p>
                    <p>&ldquo;Alexander Hamilton</p>
                    <p>My name is Alexander Hamilton</p>
                    <p>And there&rsquo;s a million things I haven&rsquo;t done</p>
                    <p>But just you wait, just you wait&rdquo;</p>
                    <p>and here are those lyrics with Alexander replaced with Alex:</p>
                    <p>&ldquo;Alex Hamilton</p>
                    <p>My name is Alex Hamilton</p>
                    <p>And there&rsquo;s a million things I haven&rsquo;t done</p>
                    <p>But just you wait, just you wait&rdquo;</p>
                    <p>Does this seem natural to you? Of course not.</p>
                    <h2>Research Methodology and Results</h2>
                    <p>Over the course of many years, I have collected data detailing the common errors in people&rsquo;s ways. Presented here for you convenience:</p>
                    <div>
                    <table>
                    <tbody>
                    <tr>
                    <td>Usage</td>
                    <td>Alexander</td>
                    <td>Alex</td>
                    <td>Andrew</td>
                    <td>Other [1]</td>
                    </tr>
                    <tr>
                    <td>Frequency</td>
                    <td>60%</td>
                    <td>20%</td>
                    <td>15%</td>
                    <td>5%</td>
                    </tr>
                    </tbody>
                    </table>
                    </div>
                    <h2>Data Analysis and Possible Explanations</h2>
                    <p>Now, while some may say a 60% success rate is quite dandy, I would like to quickly proposit that in most classes, a 60% is a D, if even. And okay, D&rsquo;s do get diplomas&hellip; but that&rsquo;s not the point! --Ahem, let us address the main causes of the first mis-usage of my name: Alex.</p>
                    <p>Possible reasonings</p>
                    <ol>
                    <li>Alexander is too long to spell</li>
                    <ol>
                    <li>In the event that writing or typing an extra five characters is simply too unbearable, I will kindly suggest examining the following resources to aid in your condition:</li>
                    <li><a href="https://www.arthritis.org/health-wellness/about-arthritis/understanding-arthritis/do-i-have-arthritis" target="_blank" rel="noopener">https://www.arthritis.org/health-wellness/about-arthritis/understanding-arthritis/do-i-have-arthritis</a></li>
                    <li><a href="https://www.webmd.com/pain-management/carpal-tunnel/carpal-tunnel-syndrome" target="_blank" rel="noopener">https://www.webmd.com/pain-management/carpal-tunnel/carpal-tunnel-syndrome</a></li>
                    <li>On average, my name will be written on my paper around two to three times, and saving the five extra characters for a grand total of fifteen characters (three words), while admirable, is ultimately a fruitless venture.</li>
                    </ol>
                    <li>Alexander is too long to pronounce</li>
                    <ol>
                    <li>On average, saying the word &ldquo;Alexander&rdquo; as opposed to &ldquo;Alex&rdquo; results in a time differential of around 0.7 seconds.&nbsp;</li>
                    <li>The name consists of four syllables. I suggest practicing it a handful of times for maximum effectiveness.</li>
                    <li>A video guide may be beneficial: <a href="https://www.google.com/search?q=alexander+pronunciation" target="_blank" rel="noopener">https://www.google.com/search?q=alexander+pronunciation</a></li>
                    </ol>
                    </ol>
                    <p>Once again, I humbly thank you for your efforts.</p>
                    <p>However, the second mis-usage may be less forgiving: Andrew.</p>
                    <p>You may be thinking to yourself: What? This must be some sort of jest, right? Unfortunately, it has not (Seriously). It has gotten to the point of which almost the same amount of people mis-use the term Alex and Andrew.</p>
                    <p>Possible reasonings</p>
                    <ol>
                    <li>The Mandela Effect</li>
                    <ol>
                    <li>Multiple timelines, 5D hyper planar replacement of surgically removed and replaced false memories among the collective body of homo sapiens.</li>
                    </ol>
                    <li>Conspiracy Against me by the NSA/FBI/etc.</li>
                    <ol>
                    <li>For legal reasons, that&rsquo;s a joke.</li>
                    </ol>
                    <li>Aliens</li>
                    <li>???</li>
                    <ol>
                    <li>Perhaps the following may be of use:</li>
                    <li><a href="https://thewire.in/science/shared-false-memories-mandela-effect" target="_blank" rel="noopener">https://thewire.in/science/shared-false-memories-mandela-effect</a></li>
                    <li><a href="https://en.wikipedia.org/wiki/Five_Eyes" target="_blank" rel="noopener">https://en.wikipedia.org/wiki/Five_Eyes</a></li>
                    <li><a href="https://www.history.com/shows/ancient-aliens" target="_blank" rel="noopener">https://www.history.com/shows/ancient-aliens</a></li>
                    </ol>
                    </ol>
                    <h2>Conclusion</h2>
                    <p>In adhering to the principles and practices detailed in the literature aforementioned, I can say in good faith that you will no longer have any troubles related to the pronunciation, recollection, or dissemination of my name.&nbsp;</p>
                    <h2>Appendix</h2>
                    <p>[1]: Other mis-usages: Alexa, Alexandra, Al, Anson, et al.</p>
                </Section>
            </div>
        </div>
    </div>
</template>

<script>
import Section from '@/components/Section';

export default {
    name: 'MyName',
    components: {
        Section
    }
}
</script>
